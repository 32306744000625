/* eslint-disable @typescript-eslint/no-unused-vars */
import { DATE_FORMAT, EMPTY_CONTENT, WHITE_SPACE } from 'constants/common';
import { ROOM_STATUS } from 'enums';
import { STORAGE_KEY } from 'enums/localStorage';
import _, { isEmpty, isNull, isUndefined } from 'lodash';
import moment from 'moment';

import { LocalStorage } from './localStorage';
import { getBranchConfig } from 'services/property';
import { AppConfigType } from 'types/property.type';
import store from 'store/Store';

const storage = new LocalStorage();
const AppConfig = storage.getStorageItem(STORAGE_KEY.APP_CONFIG) as AppConfigType;

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);
export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  const mime = (arr[0].match(/:(.*?);/) || [])[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const isSuccessCode = (code: number) => {
  return String(code).length === 4;
};

export const showData = (data: any) => {
  if (isNull(data) || isUndefined(data) || data.length === 0) return EMPTY_CONTENT;
  else return data;
};

export const getPermissions = () => {
  /**TODO: Dummy */
  const userPermission = storage.getStorageItem(STORAGE_KEY.ACCOUNT_INFO);

  return !isEmpty(userPermission) && !isEmpty(userPermission.accountFePermission)
    ? userPermission.accountFePermission
    : [];
};

export const isAllow = (permission: string[]) => {
  const listPermission: string[] = getPermissions();
  const existed = permission.every((perm) => listPermission.includes(perm));

  return existed;
};

export const formatPrice = (price: number | string) => {
  const numericString = String(price).replace(/[^0-9]/g, '');

  const numericArray = numericString.split('');
  const reversedArray = numericArray.reverse();
  const formattedArray = [];
  for (let i = 0; i < reversedArray.length; i++) {
    if (i > 0 && i % 3 === 0) {
      formattedArray.push('.');
    }
    formattedArray.push(reversedArray[i]);
  }
  const formattedString = formattedArray.reverse().join('');

  return formattedString;
};

export const formatNumber = (input: string): number => {
  const numericString = input.replace(/[^\d]/g, '');
  const numberValue = Number(numericString);

  return numberValue > 0 ? numberValue : 0;
};

export const getRoomStatus = (isMaintain?: boolean, isBooking?: boolean) => {
  if (isMaintain) return ROOM_STATUS.MAINTENANCE;
  else if (isBooking) return ROOM_STATUS.USING;
  else return ROOM_STATUS.EMPTY;
};

export const getBranchId = () => {
  const selectedBranch = storage.getStorageItem(STORAGE_KEY.BRANCH_ID);

  return selectedBranch;
};

export const compareObject = (a: any, b: any) => {
  return _.isEqual(a, b);
};

export const truncateString = (inputString: string | undefined, maxLength: number) => {
  if (Number(inputString?.length) > maxLength) {
    return inputString?.substring(0, maxLength) + '...';
  } else {
    return inputString;
  }
};

export const cleanSearchString = (inputString: string) => {
  return inputString.trim();
};

export const formatDate = (date: Date | string | undefined, formatDate?: string) => {
  if (!date) {
    return EMPTY_CONTENT;
  }

  return moment(date).format(formatDate ?? DATE_FORMAT);
};

export const isDateValid = (date: Date | string): boolean => {
  const inputMoment = moment(date);
  const dateNow = moment();

  return inputMoment.isAfter(dateNow);
};

export const parseStringToDate = (dateString: string | undefined): Date | null => {
  if (!dateString) {
    return null;
  }

  const dateParts = dateString.split('-');

  if (dateParts.length !== 3) {
    return null;
  }

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return null;
  }

  const formattedDate = new Date(year, month, day);

  if (isNaN(formattedDate.getTime())) {
    return null;
  }

  return formattedDate;
};

export const getDateTime = (timestamp: string | Date) => {
  const currentTime = moment();
  const time = moment(timestamp);
  if (currentTime.diff(time, 'days') === 0) {
    return time.format('HH:mm');
  } else {
    return time.format('HH:mm DD-MM-YYYY ');
  }
};

export const getPathName = () => {
  return window.location.pathname.replace(/\/\d+/g, '');
};

export const svgElementToBase64 = (svgElement: SVGElement) => {
  const svgXML = new XMLSerializer().serializeToString(svgElement);
  const base64EncodedSVG = btoa(unescape(encodeURIComponent(svgXML)));

  return 'data:image/svg+xml;base64,' + base64EncodedSVG;
};

export const getAccountInfo = () => {
  const accountInfo = storage.getStorageItem(STORAGE_KEY.ACCOUNT_INFO);
  if (!isEmpty(accountInfo) && !isEmpty(accountInfo.accountDetail)) {
    const userInfo = {
      fullName:
        accountInfo?.accountDetail.firstName + WHITE_SPACE + accountInfo?.accountDetail.lastName,
      phoneNumber: accountInfo?.account.phoneNumber,
      role: accountInfo?.role,
      placeOfResidence: accountInfo?.accountDetail.placeOfResidence,
    };

    return userInfo;
  }
};

export const initPWAConfig = (appConfig: AppConfigType) => {
  const myDynamicManifest = {
    name: appConfig?.crmName,
    short_name: appConfig?.crmName,
    description: appConfig?.crmName,
    start_url: '/home',
    scope: '/',
    display: 'standalone',
    theme_color: '#2196F3',
    background_color: '#2196F3',
  };
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  const manifest = document.querySelector('#manifest-placeholder');
  const iconLink = document.getElementById('link-icon');
  const iconAppleLink = document.getElementById('link-apple-icon');
  const appName = document.getElementById('app-name');
  if (manifest) {
    manifest.setAttribute('href', manifestURL);
  }
  iconLink?.setAttribute('href', String(appConfig.crmLogo));
  iconAppleLink?.setAttribute('href', String(appConfig.crmLogo));
  if (appName) {
    appName.innerText = String(appConfig?.crmName);
  }
};

export const initConfigApp = async () => {
  const domain = window.location.hostname;
  const response = await getBranchConfig(domain);
  const { data } = response.data;
  let configApp: AppConfigType = data;
  const existedConfig = storage.getStorageItem(STORAGE_KEY.APP_CONFIG);

  if (_.isEmpty(existedConfig)) {
    configApp = {
      zaloId: '',
      crmDomain: 'localhost',
      crmName: 'MORA',
      crmLogo: 'https://raw.githubusercontent.com/The-New-Tech/apartech-document/staging/logo.svg',
    };
    window.location.reload();
  }
  storage.setStorageItem(STORAGE_KEY.APP_CONFIG, configApp);
  initPWAConfig(configApp);
};

export const getAppName = () => {
  return !_.isEmpty(AppConfig) ? String(AppConfig?.crmName) : 'MORA';
};

export const getAppLogo = () => {
  return !_.isEmpty(AppConfig) ? String(AppConfig?.crmLogo) : '/logo.png';
};

import {
  createBuildingInBranch,
  createFloorInBuilding,
  deleteFloorInBuilding,
  getBuildingActiveInBranch,
  getBuildingDetail,
  getBuildingInBranch,
  getFloorInBuilding,
  getListOptionPaymentMethod,
  getListPaymentMethodPaging,
  updateBuildingInformation,
} from 'services/property';
import { CommonReq, PagingReq } from 'types/common.type';
import { CreateBuildingReq, FloorReq, UpdateBuildingReq } from 'types/property.type';
import { isSuccessCode } from 'utils/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FloorNameType } from 'types/floor.type';

export const getBuildingInBranchAction = createAsyncThunk(
  'property/getBuildingInBranchAction',
  async () => {
    const response = await getBuildingInBranch();

    return response.data;
  },
);
export const getBuildingActiveInBranchAction = createAsyncThunk(
  'property/getBuildingActiveInBranchAction',
  async (payload: CommonReq) => {
    const response = await getBuildingActiveInBranch(payload);

    return response.data;
  },
);

export const getBuildingDetailAction = createAsyncThunk(
  'property/getBuildingDetailAction',
  async (payload: CommonReq) => {
    const response = await getBuildingDetail(payload);

    return response.data;
  },
);

export const updateBuildingInformationAction = createAsyncThunk(
  'property/updateBuildingInformationAction',
  async (payload: UpdateBuildingReq & CommonReq) => {
    const response = await updateBuildingInformation(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const createBuildingInformationAction = createAsyncThunk(
  'property/createBuildingInformationAction',
  async (payload: CreateBuildingReq & CommonReq) => {
    const response = await createBuildingInBranch(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const getFloorInBuildingAction = createAsyncThunk(
  'property/getFloorInBuildingAction',
  async (payload: CommonReq) => {
    const response = await getFloorInBuilding(payload);

    return response.data;
  },
);

export const createFloorInBuildingAction = createAsyncThunk(
  'property/createFloorInBuildingAction',
  async (payload: FloorReq & CommonReq) => {
    const response = await createFloorInBuilding(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const deleteFloorInBuildingAction = createAsyncThunk(
  'property/deleteFloorInBuildingAction',
  async (payload: FloorReq & CommonReq) => {
    const response = await deleteFloorInBuilding(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return {
      ...response.data,
      data: {
        floorId: payload.floorId,
      },
    };
  },
);

export const getListPaymentMethodPagingAction = createAsyncThunk(
  'property/getListPaymentMethodPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListPaymentMethodPaging(payload);

    return response.data;
  },
);

export const getListOptionPaymentMethodAction = createAsyncThunk(
  'property/getListOptionPaymentMethodAction',
  async (payload: CommonReq) => {
    const response = await getListOptionPaymentMethod(payload);

    return response.data;
  },
);

export const createBulkFloorsAction = createAsyncThunk(
  'property/createBulkFloors',
  async ({ buildingId, floors }: { buildingId: string; floors: FloorNameType[] }) => {
    for (const floor of floors) {
      await createFloorInBuilding({ buildingId, name: floor.name });
    }

    return true;
  },
);
